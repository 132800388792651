import dateformat from 'dateformat'
import { translateManager as t } from '../services/TranslateManager'
import dates from '../locale/custom/dates.json'
import { isClient } from './runtimeUtils'

if (isClient()) {
  const language = window.__remixContext?.state?.loaderData?.root.language
  dateformat.i18n.dayNames = dates[language].dayNames.concat(
    dates[language].dayNames
  )
  dateformat.i18n.monthNames = dates[language].monthNames.concat(
    dates[language].monthNames
  )
}

export function isoToDeliveryDate(isoString, format = t.getDateFormat()) {
  return dateformat(new Date(isoString), format)
}

// Output format: "May 25 - May 29"
export function formatIsoDateRange(startDateISO, endDateISO) {
  const rangeDateFormat = 'mmm d'
  return `${isoToDeliveryDate(
    startDateISO,
    rangeDateFormat
  )} - ${isoToDeliveryDate(endDateISO, rangeDateFormat)}`
}

export function isoToOrderManagementDate(isoString) {
  return isoString
    ? dateformat(new Date(isoString), 'mmmm dd, yyyy, hh:mm:ss TT')
    : null
}

export function getTranslatedDate(date) {
  return dateformat(date, 'mmmm dd, yyyy')
}

export function addDaysToIsoDate(isoString, days) {
  const newDate = new Date(isoString)
  newDate.setDate(newDate.getDate() + days)
  return newDate.toISOString()
}

export const daysSince = (date) => {
  return Math.floor((new Date() - date) / 86400000)
}

export const utcTimestampToLocalTime = (timestamp) => {
  return new Date(timestamp + new Date().getTimezoneOffset() * 60000)
}

// Calculate the number of days between two iso dates
export function daysBetween(startDateISO, endDateISO) {
  const start = new Date(startDateISO)
  const end = new Date(endDateISO)
  return Math.floor((end - start) / 86400000)
}
