import StorageManager from './StorageManager'
import { PROMO_CODE_SOURCE, promoCodeManager } from './PromoCodeManager'
import { getUrlParam } from '../utils/UrlUtils'
import { utcTimestampToLocalTime } from '../utils/DateTimeUtils'
import { translateManager } from './TranslateManager'
import { experimentManager } from './ExperimentManager/ExperimentManager'
import { PRODUCT_TYPES, productTypeState } from './ProductTypeState'

const DISPLAYED_PROMO_CODE_POPUP_EXPERIMENTS_LOCAL_STORAGE_KEY =
  'displayedPromoCodePopupExperiments'

class GenericBannerManager {
  constructor() {
    this.appliedPromoCodeFromBanner = null
    this.subscribeToPromoCodeSubject()
  }

  get displayedPromoCodePopupExperiments() {
    return (
      StorageManager.get(
        DISPLAYED_PROMO_CODE_POPUP_EXPERIMENTS_LOCAL_STORAGE_KEY
      ) || []
    )
  }

  subscribeToPromoCodeSubject() {
    promoCodeManager.promoCodeSubject.subscribe(
      ({ resetBannerPromoCodeUsageInSession } = {}) => {
        if (resetBannerPromoCodeUsageInSession) {
          this.resetBannerPromoCodeUsageInSession()
        }
      }
    )
  }

  getTimeLeft(bannerConfig) {
    return bannerConfig.timerDeadline - new Date()
  }

  isVisible(bannerConfig) {
    return !bannerConfig.hasTimer || this.getTimeLeft(bannerConfig) > 0
  }

  getBanner() {
    const bannerConfig = experimentManager
      .getAllFeatures()
      .filter(
        (feature) =>
          feature.startsWith('banner') &&
          experimentManager.isEnabled(feature) &&
          !!experimentManager.getVariantPayload(feature)
      )
      .map((feature) => {
        const bannerConfig = experimentManager.getVariantPayload(feature)
        return {
          ...bannerConfig,
          identifier: feature,
          timerDeadline:
            bannerConfig.timerDeadline &&
            utcTimestampToLocalTime(bannerConfig.timerDeadline),
        }
      })
      .find(this._isActiveBanner)

    if (!bannerConfig) {
      return null
    }

    let currentPromoCode = promoCodeManager.getDiscountCouponDisplayName()
    currentPromoCode = currentPromoCode || getUrlParam('promo')
    if (currentPromoCode && currentPromoCode !== bannerConfig.promoCode) {
      return null
    }

    return bannerConfig
  }

  _getBannerPromoCode(banner) {
    const bannerConfig = banner || this.getBanner()
    if (!bannerConfig || !bannerConfig.promoCode) {
      return { code: null, shouldApply: false, shouldDisplayPopup: false }
    }

    const { identifier, promoCode: code } = bannerConfig

    const isGenericBannerProduct =
      productTypeState.getProductType() === PRODUCT_TYPES.CLASSIC ||
      productTypeState.getProductType() === PRODUCT_TYPES.GIFT_CARD
    return {
      identifier,
      code,
      // Checks whether this coupon has been applied in this session OR the user has already redeemed credits from the coupon in the past
      shouldApply:
        this.appliedPromoCodeFromBanner !== code &&
        !promoCodeManager.getCreditsFromCoupon(code) &&
        isGenericBannerProduct,
      shouldDisplayPopup:
        !this.displayedPromoCodePopupExperiments.includes(identifier),
    }
  }

  hasBanner() {
    const bannerConfig = this.getBanner()
    return (
      bannerConfig && bannerConfig.promoCode && this.isVisible(bannerConfig)
    )
  }

  ensureBannerPromoIsApplied(banner) {
    const { identifier, code, shouldApply, shouldDisplayPopup } =
      this._getBannerPromoCode(banner)
    if (shouldApply) {
      promoCodeManager.updateCoupon({
        coupon: code,
        source: PROMO_CODE_SOURCE.BANNER,
        hideCouponPopup: !shouldDisplayPopup,
      })
      this._setPopupDisplayedForExperiment(identifier)
      this.appliedPromoCodeFromBanner = code
      if (shouldDisplayPopup) {
        return { coupon: code }
      }
    }
    return null
  }

  resetBannerPromoCodeUsageInSession() {
    this.appliedPromoCodeFromBanner = null
  }

  _setPopupDisplayedForExperiment(experimentIdentifier) {
    if (
      !this.displayedPromoCodePopupExperiments.includes(experimentIdentifier)
    ) {
      StorageManager.set(
        DISPLAYED_PROMO_CODE_POPUP_EXPERIMENTS_LOCAL_STORAGE_KEY,
        [...this.displayedPromoCodePopupExperiments, experimentIdentifier]
      )
    }
  }

  _isActiveBanner(bannerConfig) {
    const isBeforeDeadline =
      !bannerConfig.timerDeadline || bannerConfig.timerDeadline - new Date() > 0
    const isTranslated =
      (bannerConfig.titleText &&
        translateManager.getLanguage() in bannerConfig.titleText) ||
      false
    return isBeforeDeadline && isTranslated
  }
}

export const genericBannerManager = new GenericBannerManager()
